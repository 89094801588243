













































































































































































































































































































































































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import AbpBase from '../../lib/abpbase';
import Util from '../../lib/util'
import VWidget from '../VWidget.vue';
import PageRequest from '../../store/entities/page-request';
import CusDatePicker from '../controls/cus-date-picker.vue';
import * as XLSX from 'xlsx'

@Component({
    components: { VWidget, CusDatePicker },
    data() {
        return {
            selected: [],
            options: {},
        }
    },
    computed: {
    }
})

export default class ListView extends AbpBase {
    @Prop({ type: String }) entity: string;
    @Prop({ type: Array }) columns: Array<any>;
    @Prop({ type: Array }) exportColumns: Array<any>;
    @Prop({ type: Object, default: () => { return {} } }) exclude: any;
    @Prop({ type: Array, default: () => { return [] } }) extraViews: any;
    @Prop({ type: String, default: '#f8f8f9' }) toolbarColor: string
    @Prop({ type: String, default: '' }) classRow: string
    @Prop({ type: String, default: '' }) title: string
    @Prop({ type: String, default: 'list' }) icon: string
    @Prop({ type: String, default: 'getAll' }) source: string
    @Prop({ type: String, default: 'Nuevo' }) newBtnText: string
    @Prop({ type: String, default: 'list' }) propList: string
    @Prop({ type: String, default: 'totalCount' }) propTotal: string
    @Prop({ type: String, default: 'loading' }) propLoading: string
    @Prop({ type: String, default: 'list' }) viewType: string
    @Prop({ type: String, default: 'id' }) itemKey: string        
    @Prop({ type: Boolean, default: true }) showListType: boolean;
    @Prop({ type: Boolean, default: true }) showLists: boolean;
    @Prop({ type: Boolean, default: true }) showBlocks: boolean;
    @Prop({ type: Boolean, default: true }) blockChipMain: boolean;
    @Prop({ type: Boolean, default: false }) blockCustomTop: boolean;
    @Prop({ type: Boolean, default: false }) blockCustom: boolean;
    @Prop({ type: String, default: 'top' }) blockActPos: string; //top|bottom
    @Prop({ type: String, default: '3' }) blockColSize: string;
    @Prop({ type: Boolean, default: false }) blockClick: boolean;
    @Prop({ type: String, default: '5' }) loadLines: string;        
    @Prop({ type: Boolean, default: false }) simpleList: boolean;
    @Prop({ type: Boolean, default: true }) showFilters: boolean;
    @Prop({ type: Boolean, default: true }) showPagination: boolean;
    @Prop({ type: Boolean, default: true }) showToolbarButtons: boolean;
    @Prop({ type: Boolean, default: true }) showSearch: boolean;
    @Prop({ type: Boolean, default: true }) showNewBtn: boolean;
    @Prop({ type: Boolean, default: false }) showClearBtn: boolean;
    @Prop({ type: Boolean, default: false }) forceNewBtn: boolean;
    @Prop({ type: Boolean, default: true }) showExportBtn: boolean;
    @Prop({ type: Boolean, default: false }) showImportBtn: boolean;
    @Prop({ type: Boolean, default: true }) showBadge: boolean;
    @Prop({ type: Boolean, default: true }) showHeader: boolean;
    @Prop({ type: Boolean, default: false }) showExtraHeader: boolean;        
    @Prop({ type: Boolean, default: false }) showFooter: boolean; 
    @Prop({ type: Boolean, default: false }) clientSide: boolean;
    @Prop({ type: Boolean, default: false }) showSelect: boolean;
    @Prop({ type: Boolean, default: true }) singleSelect: boolean;
    @Prop({ type: Boolean, default: false }) disableSelect: boolean;        
    @Prop({ type: Boolean, default: true }) autoLoad: boolean;
    @Prop({ type: Boolean, default: false }) selectableRows: boolean;
    @Prop({ type: Boolean, default: true }) loadAfterDelete: boolean;
    @Prop({ type: Boolean, default: false }) highlightRow: boolean;
    @Prop({ type: Boolean, default: false }) editableInRow: boolean;
    @Prop({ type: Boolean, default: false }) editOnDblClick: boolean;
    @Prop({ type: Boolean, default: false }) customExport: boolean;
    @Prop({ type: Array, default: () => { return null } }) extraToolbarButtons: any;
    @Prop({ type: Array, default: () => { return null } }) sortBy: any;
    @Prop({ type: Array, default: () => { return null } }) sortDesc: any;
    
    @Prop({ type: Array }) extList: Array<any>;
    @Prop({ type: PageRequest, default: () => { return new PageRequest() } }) pagerequest: PageRequest;
    pagination: object = {};
    enableLoad: boolean = true;
    changed: boolean = false;
    totalitems: number = 0;
    highlightIndex: number = -1;
    lastColor: string = null;
    editing: number = 0;
    vType: string = 'list';
    async pageChange(page: number, load: boolean = false) {
        if (this.currentPage == page || (!this.showPagination && this.simpleList)) return;
        this.$store.commit(this.entity + '/setCurrentPage', page);
        if (load) await this.getpage();
    }
    pagesizeChange(pagesize: number, load: boolean = false) {
        if (this.pageSize == pagesize || (!this.showPagination && this.simpleList)) return;
        this.$store.commit(this.entity + '/setPageSize', pagesize);
        if (load) this.getpage();
    }
    async changeOptions(options) {
        if (!this.autoLoad) return;
        this.changed = true;

        this.pageChange(options.page);
        this.pagesizeChange(options.itemsPerPage);
        await this.getpage();
    }
    propValue(data: any, field: string) {
        let value = Util.getEntityValue(data, field);
        return value;
    }
    propData(data: any, col: any) {
        let value = this.propValue(data, col.value);
        if (col.generalType) value = Util.abp.list.getName(value);
        if (col.entity != undefined) {
            let entityObj = Util.getEntity(this, col.entity, value);
            if (entityObj != undefined) {
                value = entityObj[(col.entityField == undefined ? 'name' : col.entityField)];
            }
        }

        if (col.type == 'percent')
            value += ' %';
        else if (col.type == 'price' || col.textType == 'price') {
            let sign = (data['currency_sign'] != undefined ? data['currency_sign'] : 
                       (data['currency'] != undefined ? data['currency'].sign : Util.abp.setting.get('DEFAULT_CURRENCY_SIGN')));
            value = sign + ' ' + (value != null ? parseFloat(value).toFixed(col.decimals) : 0); 
        } else if (col.type == 'number') {
            value = (value != null ? parseFloat(value).toFixed(col.decimals) : 0);
        } else if (col.type == 'date') {
            value = (value != null ? value.substr(0, 10) : '');
        } else if (col.type == 'list') {
            let values = value;
            let elements = [];
            if (Array.isArray(values)) {
                values.forEach(item => {
                    elements.push((typeof (item) == 'object' ? item.name : item));
                });
            }
            value = elements.join(',');
        } else if (col.type == 'progress') {
            value = (value != null ? parseFloat(value).toFixed(0) : 0);
        }
        
        return value;
    }
    getColor(data: any, col: any) {
        let style = 'none';
        if (col.color != undefined) {
            if (col.color == 'extra' && col.generalType == true) {
                let val = this.propValue(data, col.value);
                let item = Util.abp.list.getItem(val);
                style = (item != undefined ? item.extra : '');
            } else
                style = this.propValue(data, col.color);
        }
        return style;
    }
    hasGroupActions(col, id) {
        let obj = this;
        if (col.groupActions != null) {
            let res = false;
            col.groupActions.forEach(item => {
                if (item.name != 'divider') {
                    if (obj.exclude[item.name] == undefined) {
                        res = true;
                    } else if (obj.exclude[item.name].indexOf(id) < 0) {
                        res = true;
                    }
                }
            });
            return res;
        } else
            return false;
    }
    allowAction(action, id) {
        let res = true;
        if (this.exclude[action] != undefined) {
            let pos = this.exclude[action].indexOf(id);
            res = pos < 0;
        }
        return res;
    }

    get toolButtons() {
        let btns = [];

        if (this.extraToolbarButtons != null) 
            btns.push(...this.extraToolbarButtons);

        if (this.canAdd && (this.simpleList || this.$vuetify.breakpoint.xsOnly))
            btns.push({ icon: 'add', shortkey: ['alt', 'n'], label: this.L(this.newBtnText), click: this.create });

        if (this.showClearBtn && !this.simpleList)
            btns.push({ icon: 'clear_all', label: this.L('Limpiar'), click: this.clear });

        if (!this.simpleList)
            btns.push({ icon: 'refresh', label: this.L('Actualizar'), shortkey: ['alt', 'l'], click: this.getpage });

        if (!this.simpleList && this.showExportBtn && this.canExport())
            btns.push({ icon: 'mdi-file-excel', label: this.L('Exportar a Excel'), shortkey: ['alt', 'e'], click: this.exportToExcel, color: (this.customExport ? 'success':null) });

        if (!this.simpleList && this.showImportBtn && (this.canCreate() || this.canEdit()))
            btns.push({ icon: 'mdi-upload', label: this.L('Importar Registros'), shortkey: ['alt', 'i'], click: this.importData });

        return btns;
    }
    get currentTitle() {
        let title = this.title;
        let obj = (this as any);
        if (obj.title != '') return obj.title;
        let paths = this.$store.state.app.currentPath;
        return 'Lista de ' + paths[paths.length - 1].meta.title;
    }
    get badge() {
        if (!this.showBadge) return -1;
        return this.totalCount;
    }
    get list() { 
        if (this.extList != undefined) return this.extList;
        return this.$store.state[this.entity][this.propList];
    }
    get loading() {
        return this.$store.state[this.entity][this.propLoading];
    }
    get pageSize() {
        return this.$store.state[this.entity].pageSize;
    }
    get totalCount() {
        if (this.clientSide) return -1;
        if (this.extList != undefined) return this.extList.length;
        return this.$store.state[this.entity][this.propTotal];
    }
    get currentPage() {
        return this.$store.state[this.entity].currentPage;
    }
    get canAdd() {
        let list = Util.abp.tabList;
        return (this.canCreate() && this.showNewBtn) || this.forceNewBtn;
    }
    get skeletonType() {
        return 'table-row-divider@' + this.loadLines;
    }

    //v-data-table EVENTS
    getColSort(prop) {
        this.columns.forEach(item => {
            if (item.value == prop) {
                prop = (item.sort != undefined ? item.sort : item.value);
            }
        });
        return prop;
    }

    setSorting() {
        let obj = (this as any);

        this.pagerequest.sorting = [];
        if (obj.options.sortBy != null && obj.options.sortBy.length) {
            obj.options.sortBy.forEach((item, index) => {
                let sort = obj.getColSort(item) + '-' + (obj.options.sortDesc[index] ? 'desc' : 'asc');
                this.pagerequest.sorting.push(sort);
            });
        }
    }

    //block EVENTS
    getMainItem(data) {
        var title = '';
        let main = this.columns.filter((col, index) => {
            return col.main == true;
        })[0];

        if (main == null) {
            let name = this.columns.filter((col, index) => {
                return col.key == 'code' || col.key == 'nro' || col.key == 'number';
            })[0];
            if (name != null) {
                name.main = true;
                title = data[name.value];
            }
        } else 
            title = data[main.value];
        
        return title;
    }

    get stateCol() {
        return this.columns.filter(col => {
            return (col.state == true);
        })[0];
    }

    get actionCol() {
        return this.columns.filter(col => {
            return (col.value == 'action');
        })[0];
    }

    @Emit('on-block-click')
    onBlockClick(item) {
    }

    //general EVENTS
    viewItem(item) {
        this.$store.commit(this.entity + '/view', item);
        this.$emit('on-click-view');
    }

    editItem(item) {
        this.$store.commit(this.entity + '/edit', item);
        this.$emit('on-click-edit');

        if (this.editableInRow) 
            this.editing = item.id;
    }

    cancelEdit(item) {
        if (this.editableInRow)
            this.editing = 0;
    }

    printItem(item) {
        this.$emit('on-click-print', item);
    }

    deleteItem(item) {
        let title = this.$router.currentRoute.meta.title;

        this.$swal({
            title: '&iquest;Est&aacute; seguro?',
            html: 'Esto eliminar&aacute; este registro de ' + title + ' y no se podra revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Eliminar!',
            cancelButtonText: '&iexcl;No!', 
            cancelButtonColor: '#FF5252',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                await this.$store.dispatch({
                    type: this.entity + '/delete',
                    data: item
                });
                if (this.loadAfterDelete)
                    await this.getpage();
                this.$emit('on-click-delete');

                this.$swal('Eliminado', 'Has eliminado este registro correctamente', 'success')
            }
        })
    }

    create() {
        this.$store.commit(this.entity + '/new');
        this.$emit('on-click-create');
    }

    eventItem(event, item) {
        if (typeof event === 'string')
            this[event](item);
        else if (typeof event === 'function')
            event(item);
    }

    updateColor(value) {
        this.lastColor = value;
    }

    saveColor(col, data, lastColor) {
        if (lastColor != data[col.value] && lastColor != null && lastColor != '') {
            data[col.value] = lastColor;
            if (col.save != undefined)
                col.save(data);
        }
    }
    
    @Emit('on-getpage')
    async getpage() {
        if (this.clientSide || !this.enableLoad) return;
        
        this.pagerequest.perPage = this.pageSize;
        this.pagerequest.page = this.currentPage;//(this.currentPage - 1) * this.pageSize;
        this.setSorting();

        await this.$store.dispatch({
            type: this.entity + '/' + this.source,
            data: this.pagerequest
        });
        (this.pagination as any).totalItems = this.totalCount;
        this.$emit('after-getpage');
    }

    @Emit('on-row-click')
    onRowClick(row: any, index: any) {
        if (this.selectableRows || this.highlightRow) {
            if (this.highlightIndex == index)
                this.highlightIndex = -1;
            else
                this.highlightIndex = index;
        }
    }

    @Emit('on-row-dbl-click')
    onRowDblClick(row: any, index: any) {
        if (this.editOnDblClick) {
            this.editItem(row);
        }
    }

    @Emit('on-select-items')
    onSelecteItems(items) {
        //console.log(items);
    }

    @Emit('on-clear')
    clear() {
    }

    @Emit('on-change-view')
    changeView(view) {
    }

    exportToExcel() {
        if (this.customExport) {
            this.$emit('on-export-data');   
        } else {
            var columns = this.columns;
            if (this.exportColumns != null)
                columns = this.exportColumns;

            let dataList = Util.getListToExport(this.list, columns);
            let mai = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "U", "V", "W", "X", "Y", "Z"];

            let data = XLSX.utils.json_to_sheet(dataList.list, { header: dataList.header });

            dataList.headerTitle.forEach((title, index) => {
                data[mai[index] + '1'].v = title
            });

            const workbook = XLSX.utils.book_new();
            const filename = this.entity + '-list';
            XLSX.utils.book_append_sheet(workbook, data, filename);
            XLSX.writeFile(workbook, `${filename}.xlsx`);
        }
    }

    @Emit('on-import-data')
    importData() {

    }

    async onKeyup(event) {
        if (event.srcKey == 'up' || event.srcKey == 'down')
            await this.onMoveSelection(event);
        else {
            this.changed = true;
            switch (event.srcKey) {
                case 'pageup':
                    if (this.currentPage == 1) return;
                    this.changed = false;
                    (this as any).options.page = this.currentPage - 1;
                    break;
                case 'pagedown':
                    if ((this.currentPage * this.pageSize) < this.totalCount) {
                        this.changed = false;
                        (this as any).options.page = this.currentPage + 1;
                    }
                    break;
                case 'home':
                    if (this.currentPage == 1) return;
                    this.changed = false;
                    (this as any).options.page = 1;
                    break;
                case 'end':
                    let pag = Math.ceil(this.totalCount / this.pageSize);
                    if (this.currentPage == pag) return;
                    this.changed = false;
                    (this as any).options.page = pag;
                    break;
            }
            setTimeout(() => {
                if (!this.changed) this.changeOptions((this as any).options);
            }, 100);
        }
    }

    async onMoveSelection(event) {
        if (this.selectableRows) {
            switch (event.srcKey) {
                case 'up':
                    if (this.highlightIndex > 0)
                        this.highlightIndex--;
                    else {
                        if (this.currentPage > 1) {
                            this.pageChange(this.currentPage - 1);
                            (this as any).options.page = this.currentPage;
                            if ((this as any).options.page % 2 != 0) {
                                await this.getpage();
                            }
                            this.highlightIndex = this.pageSize - 1;
                        } else
                            this.highlightIndex = 0;
                    }

                    break;
                case 'down':
                    if (this.highlightIndex < 0)
                        this.highlightIndex = 0;
                    else
                        this.highlightIndex++;

                    if (this.highlightIndex >= this.list.length) {
                        if ((this.currentPage * this.pageSize) < this.totalCount) {
                            this.pageChange(this.currentPage + 1);
                            (this as any).options.page = this.currentPage;
                            if ((this as any).options.page % 2 != 0) {
                                await this.getpage();
                            }

                            this.highlightIndex = 0;
                        } else
                            this.highlightIndex = this.list.length - 1;
                    }

                    break;
            }
            this.$emit('move-selection');
        }
    }

    async mounted() {
        if (this.vType == 'block')
            await this.getpage();
    }
}
